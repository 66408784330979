import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  LOCALE_ID,
  importProvidersFrom,
} from '@angular/core';
import {
  PreloadAllModules,
  Router,
  provideRouter,
  withPreloading,
} from '@angular/router';
import {
  CORE_PROVIDERS,
  CustomMissingTranslationHandler,
  CustomTranslateLoader,
  EnvironmentService,
  LocalIdService,
} from '@app/core';
import { ROUTES } from './app/app.routes';
import {
  TranslateModule,
  TranslateLoader,
  TranslatePipe,
  MissingTranslationHandler,
} from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthClientConfig, AuthModule } from '@auth0/auth0-angular';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { EON_ONE_UI_PROVIDERS } from '@eon-one/angular-customizations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeDE from '@angular/common/locales/de';
import { CurrencyPipe, DOCUMENT, registerLocaleData } from '@angular/common';
import { CurrencyImpurePipe } from '@app/shared';
import * as Sentry from '@sentry/angular-ivy';
import { GoogleTagManagerConfiguration } from 'angular-google-tag-manager';

function initializeApp(
  authClientConfig: AuthClientConfig,
  environmentService: EnvironmentService,
  googleTagManagerConfiguration: GoogleTagManagerConfiguration,
  doc: Document
): () => void {
  return () => {
    authClientConfig.set({
      domain: environmentService.issuer,
      clientId: environmentService.clientId,
      authorizationParams: {
        scope: 'openid profile email offline_access',
        redirect_uri: doc.baseURI,
      },
    });

    googleTagManagerConfiguration.set({
      id: environmentService.gtmId,
      gtm_preview: environmentService.gtmPreview,
      gtm_auth: environmentService.gtmAuth,
    });
  };
}

registerLocaleData(localeDE);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(ROUTES, withPreloading(PreloadAllModules)),
    importProvidersFrom(HttpClientModule), // or provideHttpClient() in Angular v15
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: CustomTranslateLoader,
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: CustomMissingTranslationHandler,
        },
      })
    ),
    importProvidersFrom(AuthModule.forRoot()),
    importProvidersFrom(BrowserAnimationsModule),
    ...CORE_PROVIDERS,
    ...EON_ONE_UI_PROVIDERS,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: LOCALE_ID, useValue: 'en-EN' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [
        AuthClientConfig,
        EnvironmentService,
        GoogleTagManagerConfiguration,
        DOCUMENT,
        Sentry.TraceService,
      ],
      multi: true,
    },
    CurrencyPipe,
    CurrencyImpurePipe,
    TranslatePipe,
    LocalIdService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
};
