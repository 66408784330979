import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import {
  ErrorHandlerService,
  NominatimService,
  RestrictionService,
  SearchService,
  UserService,
} from '@app/core';
import {
  DEFAULT_RANK_BY,
  RankBy,
} from '@app/map/components/sidebar/components/spots-found/components/spots-ranking';
import { SearchResolverOutput } from '@app/map/resolver/search-resolver-output.interface';
import {
  APP_ROUTES,
  COUNTRY_CODE,
  FORM_NAME,
  GeocodingResponseDto,
  SearchFormConfig,
  SearchFormValue,
  transformRadiusOptions,
} from '@app/shared';
import { cloneDeep, isNil } from 'lodash-es';
import { map, Observable, of, tap } from 'rxjs';

type SearchQueryParams = { [key in FORM_NAME]: string };

@Injectable({
  providedIn: 'root',
})
export class SearchResolver {
  public static readonly DEFAULT_OUTPUT: Observable<SearchResolverOutput> = of({
    projectId: undefined,
    geocodingResult: undefined,
  });

  readonly #router = inject(Router);
  readonly #errorHandlerService = inject(ErrorHandlerService);
  readonly #geocodingService = inject(NominatimService);
  readonly #searchService = inject(SearchService);
  readonly #restrictionService = inject(RestrictionService);
  readonly #userService = inject(UserService);

  public resolve(
    route: ActivatedRouteSnapshot
  ): Observable<SearchResolverOutput> {
    const originQueryParams = cloneDeep(route.queryParams) as SearchQueryParams;

    if (!this.#isValidSearchQueryParam(originQueryParams)) {
      this.#errorHandlerService.handleError(new Error('Missing query params'));
      this.#router.navigate([`/${APP_ROUTES.HOME}`]);
      return SearchResolver.DEFAULT_OUTPUT;
    }

    if (this.#restrictionService.radiusRestrictions()) {
      const plan = this.#userService.subscriptionPlan();
      const areaIndex = Number(originQueryParams[FORM_NAME.CITY_OR_AREA]);

      this.#searchService.lastSubmittedSearch = this.#buildSearchFormValue(
        areaIndex,
        originQueryParams
      );

      if (plan) {
        const rankBy = originQueryParams[FORM_NAME.RANK_BY] || DEFAULT_RANK_BY;

        return of({
          geocodingResult: {
            /*eslint-disable @typescript-eslint/no-extra-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain */
            longitude: plan?.details?.info?.areas[areaIndex].longitude!,
            latitude: plan?.details?.info?.areas[areaIndex].latitude!,
            /*eslint-enable @typescript-eslint/no-extra-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain */

            radiusMeter: parseInt(
              originQueryParams[FORM_NAME.RADIUS_IN_METER],
              10
            ),
            chargingStations: parseInt(
              originQueryParams[FORM_NAME.AMOUNT_OF_CHARGING_STATIONS],
              10
            ),
            locationMaxKW: parseInt(
              originQueryParams[FORM_NAME.MAX_CAPACITY_IN_KW],
              10
            ),
            rankBy: rankBy as RankBy,
            countryCode: (
              plan?.details?.info?.areas[areaIndex]?.countryCode ?? ''
            ).toUpperCase() as COUNTRY_CODE,
          },
        });
      }
    }

    originQueryParams[FORM_NAME.CITY_OR_AREA] =
      originQueryParams[FORM_NAME.CITY_OR_AREA].toUpperCase();

    return this.#geocodingService
      .searchByID(originQueryParams[FORM_NAME.CITY_OR_AREA])
      .pipe(
        map(geocodingResults =>
          this.#examineGeocodingResult(geocodingResults, originQueryParams)
        ),
        tap(geocodingResult => {
          this.#searchService.lastSubmittedSearch = this.#buildSearchFormValue(
            geocodingResult,
            originQueryParams
          );
        }),
        map((geocodingResult: GeocodingResponseDto): SearchResolverOutput => {
          const rankBy =
            originQueryParams[FORM_NAME.RANK_BY] || DEFAULT_RANK_BY;
          return {
            geocodingResult: {
              longitude: geocodingResult.longitude,
              latitude: geocodingResult.latitude,
              radiusMeter: parseInt(
                originQueryParams[FORM_NAME.RADIUS_IN_METER],
                10
              ),
              chargingStations: parseInt(
                originQueryParams[FORM_NAME.AMOUNT_OF_CHARGING_STATIONS],
                10
              ),
              locationMaxKW: parseInt(
                originQueryParams[FORM_NAME.MAX_CAPACITY_IN_KW],
                10
              ),
              rankBy: rankBy as RankBy,
              countryCode: (
                geocodingResult.address?.countryCode || ''
              ).toUpperCase() as COUNTRY_CODE,
            },
          };
        })
      );
  }

  #isValidSearchQueryParam(searchQueryParam: SearchQueryParams): boolean {
    let output = true;

    const radiusOptions = transformRadiusOptions(
      this.#restrictionService.radiusRestrictions()?.areaDetails?.[
        Number(searchQueryParam[FORM_NAME.CITY_OR_AREA])
      ].maxRadius || SearchFormConfig.INSTANCE.MAX_DEFAULT_RADIUS
    );

    if (
      !radiusOptions.includes(
        parseInt(searchQueryParam[FORM_NAME.RADIUS_IN_METER], 10)
      )
    ) {
      this.#errorHandlerService.handleError(new Error(`Radius is not valid.`));
      return false;
    }

    Object.values(FORM_NAME).forEach(value => {
      if (isNil(searchQueryParam[value])) {
        output = false;
      }
    });

    return output;
  }

  #examineGeocodingResult(
    geocodingResults: GeocodingResponseDto | undefined,
    originQueryParams: SearchQueryParams
  ): GeocodingResponseDto {
    if (isNil(geocodingResults)) {
      this.#router.navigate([`/${APP_ROUTES.HOME}`]);
      this.#errorHandlerService.handleError(
        new Error(
          `No city or area for id ${originQueryParams[FORM_NAME.CITY_OR_AREA]}.`
        )
      );
    }
    return geocodingResults!;
  }

  #buildSearchFormValue(
    geocodingResult: GeocodingResponseDto | number,
    originQueryParams: Params
  ): SearchFormValue {
    const rankBy = originQueryParams[FORM_NAME.RANK_BY] || DEFAULT_RANK_BY;

    return {
      [FORM_NAME.CITY_OR_AREA]: geocodingResult,
      [FORM_NAME.COUNTRY_CODE]: originQueryParams['countryCode'] || '',
      [FORM_NAME.RADIUS_IN_METER]: parseInt(
        originQueryParams[FORM_NAME.RADIUS_IN_METER],
        10
      ),
      [FORM_NAME.AMOUNT_OF_CHARGING_STATIONS]: parseInt(
        originQueryParams[FORM_NAME.AMOUNT_OF_CHARGING_STATIONS],
        10
      ),
      [FORM_NAME.MAX_CAPACITY_IN_KW]: parseInt(
        originQueryParams[FORM_NAME.MAX_CAPACITY_IN_KW],
        10
      ),
      [FORM_NAME.RANK_BY]: rankBy,
    };
  }
}
