import { APP_ROUTES, ValidUserGuard } from '@app/shared';
import { ProjectResolver, SearchResolver } from '@app/map/resolver';
import { LocationTableViewResolver } from '@app/location-table-page';
import { Routes } from '@angular/router';

/* eslint-disable @softarc/sheriff/deep-import */

export const ROUTES: Routes = [
  {
    path: APP_ROUTES.ERROR,
    loadComponent: () =>
      import('./error-page/error-page.component').then(
        mod => mod.ErrorPageComponent
      ),
  },
  {
    path: APP_ROUTES.HOME,
    loadComponent: () =>
      import('./home/home.component').then(mod => mod.HomeComponent),
    title: 'Home',
    canActivate: [ValidUserGuard],
  },
  {
    path: '',
    redirectTo: APP_ROUTES.HOME,
    pathMatch: 'full',
  },
  {
    path: `${APP_ROUTES.MAP}/${APP_ROUTES.MAP_CHILDREN_SEARCH}`,
    loadComponent: () =>
      import('./map/map.component').then(mod => mod.MapComponent),
    runGuardsAndResolvers: 'always',
    resolve: { resolverOutput: SearchResolver },
    canActivate: [ValidUserGuard],
  },
  {
    path: APP_ROUTES.MAP,
    loadComponent: () =>
      import('./map/map.component').then(mod => mod.MapComponent),
    title: 'Map',
    canActivate: [ValidUserGuard],
  },
  {
    path: APP_ROUTES.MAP_PROJECT_VIEW,
    loadComponent: () =>
      import('./map/map.component').then(mod => mod.MapComponent),
    title: 'Map',
    resolve: { resolverOutput: ProjectResolver },
    canActivate: [ValidUserGuard],
  },
  {
    path: APP_ROUTES.TABLE_PROJECT_VIEW,
    loadComponent: () =>
      import('./location-table-page/location-table-page.component').then(
        mod => mod.LocationTablePageComponent
      ),
    title: 'Table',
    resolve: {
      [LocationTableViewResolver.DATA_IDENTIFIER]: LocationTableViewResolver,
    },
    canActivate: [ValidUserGuard],
  },
  {
    path: APP_ROUTES.SHARED_PROJECT_VIEW,
    loadComponent: () =>
      // eslint-disable-next-line @softarc/sheriff/deep-import
      import('./shared-project/shared-project.component').then(
        mod => mod.SharedProjectComponent
      ),
    title: 'Shared project',
    resolve: {},
  },
  {
    path: `${APP_ROUTES.FREE_TO_PLAY}/${APP_ROUTES.MAP_CHILDREN_SEARCH}`,
    loadComponent: () =>
      // eslint-disable-next-line @softarc/sheriff/deep-import
      import('./free-to-play/free-to-play.component').then(
        mod => mod.FreeToPlayComponent
      ),
    runGuardsAndResolvers: 'always',
    resolve: { resolverOutput: SearchResolver },
  },
  {
    path: APP_ROUTES.FREE_TO_PLAY,
    loadComponent: () =>
      // eslint-disable-next-line @softarc/sheriff/deep-import
      import('./free-to-play/free-to-play.component').then(
        mod => mod.FreeToPlayComponent
      ),
    title: 'SpotOne',
    resolve: {},
  },
  {
    path: '**',
    redirectTo: APP_ROUTES.HOME,
  },
];

/* eslint-enable @softarc/sheriff/deep-import */
