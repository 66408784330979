<app-navbar>
  <app-global-loading-indicator />
  <app-subscription-plan-expiring-banner />

  <div class="mobile-view">
    <h1>{{ 'MOBILE_VIEW.TITLE' | translate }}</h1>
    <p>
      {{ 'MOBILE_VIEW.DESCRIPTION' | translate }}
    </p>
    <p>
      {{ 'MOBILE_VIEW.DESCRIPTION_2' | translate }}
    </p>
  </div>

  <div class="container">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer class="footer" [footerLinks]="footerLinks()"></app-footer>
</app-navbar>
