import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { ErrorHandlerService } from '@app/core';
import { SearchResolverOutput } from '@app/map/resolver/search-resolver-output.interface';
import { APP_ROUTES } from '@app/shared/';
import { EMPTY, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectResolver {
  private static readonly PROJECT_IDENTIFIER_PARAM = 'id';

  readonly #router = inject(Router);
  readonly #errorHandlerService = inject(ErrorHandlerService);

  public resolve(
    route: ActivatedRouteSnapshot
  ): Observable<SearchResolverOutput> {
    const originParams = route.params;

    if (this.isRequestWithProjectID(originParams)) {
      const projectId = originParams[ProjectResolver.PROJECT_IDENTIFIER_PARAM];

      return of({
        projectId,
      });
    } else {
      this.#errorHandlerService.handleError(new Error('Missing params'));
      this.#router.navigate([`/${APP_ROUTES.HOME}`]);

      return EMPTY;
    }
  }

  private isRequestWithProjectID(params: Params): boolean {
    return !!params[ProjectResolver.PROJECT_IDENTIFIER_PARAM];
  }
}
