import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
const EON_ONE_UI_PROVIDERS = [{
  provide: MAT_RIPPLE_GLOBAL_OPTIONS,
  useValue: {
    disabled: true,
    animation: {
      enterDuration: 300,
      exitDuration: 0
    }
  }
}];

/*
 * Public API Surface of angular-customizations
 */

/**
 * Generated bundle index. Do not edit.
 */

export { EON_ONE_UI_PROVIDERS };
