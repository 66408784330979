import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { AppComponent } from '@app/app.component';
import { environment } from '@environments/environment';
// eslint-disable-next-line @softarc/sheriff/deep-import
import { appConfig } from './app.config';

import * as Sentry from '@sentry/angular-ivy';

Sentry.init({
  environment: environment.environment,
  dsn: 'https://34fa6368298420109de243e29ab17fb7@sentry.eon.com/206',
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost'],
});

if (environment.production) {
  enableProdMode();
}

// eslint-disable-next-line no-console
bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
